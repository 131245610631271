import React, {Component} from 'react';

import fb from '../../../public/css/icon/facebookb.png'
import linkedin from '../../../public/css/icon/linkedinb.png'
import twitter from '../../../public/css/icon/twitterb.png'
import partnerImage from '../../../public/css/icon/Groupe 34.png'
import partnerImage2 from '../../../public/css/icon/Groupe 342.png'

import { Trans } from 'react-i18next';
//import Entraide from "../entraide/entraide";
import i18n from "../../i18n";


import "./footer.scss";
import  "../../styles/homepage/homepage.scss";

export default class Footer extends Component {

componentDidMount() {
    console.log('footer chargé!');
}
    render() {
        // const loading = this.state.loading;
        return (
            
            <div className="footerBackground mt-5">
                <div className="div-container-data-footer">
                    <div className="container-data-footer">
                        <div className="footer-colonne">
                            <div className="title-footer"><Trans i18nKey="footer_contact">Contact</Trans></div>
                                <ul>
                                    <li><span>Avenue des Morgines 9</span></li>
                                    <li><span>1213 Petit-Lancy</span></li>
                                    <li><span>Suisse</span></li>
                                    <li><span>Fax : 079 256 14 30</span></li>
                                    <li><span>Email : contact@repertoirevert.org</span></li>
                                    <li><span>Website : <a href="http://www.gaea21.org">www.gaea21.org</a></span></li>
                                </ul>
                            </div>
                            <div className="footer-colonne">
                                <div className="title-footer"><Trans i18nKey="main_partners">Partenaires</Trans></div>
                                <ul>
                                    <li><a href="https://www.loro.ch/fr">Loterie Romande</a></li>
                                    <li><a href="http://www.gaea21.org">gaea21</a></li>
                                </ul>
                            </div>
                            <div className="footer-colonne">
                                <div className="title-footer"><a href="/mentions-legales"> <Trans i18nKey="footer_mentions_legales">Mentions Légales</Trans> </a>
                                </div>
                            </div>
                        </div>
                </div>
                <div className="container-icones-footer">
                    <a className="icon-footer" href="https://www.facebook.com/RepertoireVertgaea21"><img
                        src={fb}/></a>
                    <a className="icon-footer" href="https://www.linkedin.com/company/repertoire-vert/"><img
                        src={linkedin}/></a>
                    <a className="icon-footer" href="../../../../../../../../Downloads#"><img src={twitter}/></a>
                </div>
              </div>
               
        );
    }
}

