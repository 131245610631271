import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from 'react-router-dom';

import {createRoot} from "react-dom/client";

// import components
 import Footer from "../../components/footer/footer";


// ./src/js/app.js

// import './Footer.scss';



// render(<Router><HomePage /></Router>, document.getElementById('rootHome'));

const rootHome = document.getElementById('footer');

// const userRole = rootHome.dataset.userRole;

//Passage à la syntaxe REACT 18
const root = createRoot(rootHome); // createRoot(container!) if you use TypeScript
// root.render(<Router><HomePage /></Router>);
root.render(<Router><Footer  /></Router>);
